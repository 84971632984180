<template>
	<v-container
			fluid
	>

		<v-row justify="center">
			<v-col cols="12">

				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="id"
								:search="search"
								:options.sync="options"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.published`]="{ item }">
								<span>{{ formatPublished(item.published) }}</span>
							</template>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col>
										<v-text-field no-gutters clearable v-model="search" label="Search news" class="ps-4 pt-6 pr-4"></v-text-field>
									</v-col>
								</v-row>
							</template>

							<template v-slot:[`item.action`]="{ item }">
								<Button
										:href="item.link"
										target="_blank"
										small text icon
										class="mr-2"
								>
									<v-icon small>mdi-eye</v-icon>
								</Button>
								<v-menu offset-y>
									<template v-slot:activator="{ on }">
										<v-icon
												v-on="on"
												small
												class="mr-2">
											mdi-settings
										</v-icon>
									</template>
									<v-list>
										<v-list-item @click="deleteNews(item)">
											<v-list-item-title>Delete</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App.vue'
	import moment from 'moment';

	export default {
		data: () => ({
			loading: false,
			search: '',
			options: {
				sortDesc: [true],
				sortBy: ['published'],
			},
			headers: [
				{ text: 'Title', value: 'title' },
				{ text: 'Description', value: 'description' },
				{ text: 'Source', value: 'source' },
				{ text: 'Published', value: 'published', width: 120 },
				{ text: 'Actions', value: 'action', align: 'center', sortable: false },
			],
			totalItems: 0,
			items: [],
		}),
		methods: {
			formatPublished(date) {
				return moment(date).format('D MMM kk:mm');
			},
			deleteNews(item) {
				this.$root.$confirm(`Delete`, `Are you sure?`, { color: `error` })
					.then((confirm) => {
						if (confirm) {
							this.$httpInt(`/v2/news/${item.id}`)
								.then(() => {
									this.items.splice(this.items.findIndex(e=> e.id === item.id),1)
									this.$root.$snackbar(`News deleted`)
								})
						}
					})
			},
			refreshContent() {
				this.loading = true
				this.$httpInt.get(`/v2/news/`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		mixins: [globalMixIn],
		name: `NewsPosts`,
		watch: {
			search: {
				handler () {
					this.refreshContent()
				},
			},
			options: {
				handler () {
					this.refreshContent()
				},
			},
		},
	}
</script>
